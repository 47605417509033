import React, { useContext } from 'react'
import { Price } from '@uniswap/sdk'


import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { Quote } from '../../utils/0x-api/types'
import { QuestionHelperContent } from '../QuestionHelper'
import { TYPE } from '../../theme'


interface PriceDetailsProps {
  price?: Price
  quote?: Quote
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void

}

export const ClickedText = styled(Text)`
  background-color: ${({ theme }) => theme.bg2};
  border: none;
  padding: 0.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 0.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg3};
    outline: none;
  }
`



export default function PriceDetails({ quote, price, showInverted, setShowInverted }: PriceDetailsProps) {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)
  const minimumPriceFormatted = showInverted ? Number(quote?.response.guaranteedPrice).toFixed(4) : (1 / Number(quote?.response.guaranteedPrice)).toFixed(4)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  const exchanges = quote?.response.sources.filter(s => Number(s.proportion) > 0)
    .map(s => <p>{s.name}: {(Number(s.proportion) * 100).toFixed(2)} %</p>)
  const priceBreakdown = (<>
    <TYPE.black fontSize={10} fontWeight={400} color={theme.text2}>
      Searched {quote?.response.sources.length} exchanges
    </TYPE.black>
    <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
      <b> We got you best prices from:</b>
    </TYPE.black>
    <hr />
    <>{exchanges}</>
    <hr />
    <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
      Best Price: {formattedPrice} {label}
    </TYPE.black>

    <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
      Minimum Price: {minimumPriceFormatted} {label}
    </TYPE.black>
  </>);


  return (
    <ClickedText
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      onClick={() => setShowInverted(!showInverted)}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <QuestionHelperContent content={priceBreakdown} />
        </>
      ) : (
          '-'
        )}
    </ClickedText>
  )
}
