import { TradeType } from '@uniswap/sdk'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
// import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE, StyledInternalLink } from '../../theme'
// import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
// import FormattedPriceImpact from './FormattedPriceImpact'
// import { SectionBreak } from './styleds'
// import SwapRoute from './SwapRoute'
import { Quote } from '../../utils/0x-api/types'
import { useTokenFeeProgram } from '../../state/swap/hooks'
import { YellowCard } from '../Card'
import { getConfig } from '../../constants/config'
import FiatCard from '../FiatCard'


function QuoteSummary({ quote, allowedSlippage }: { quote: Quote; allowedSlippage: number }) {
  const theme = useContext(ThemeContext)
  //  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = quote.tradeType === TradeType.EXACT_INPUT
  const guarantedPrice = Number(quote.response.guaranteedPrice);
  // const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(quote, allowedSlippage)
  const { buyTokenFeePercentage, normalBuyTokenFeePercentage, premiumBuyTokenFeePercentage, feeTokenAmount, isProgramEnable, tokenAmountToHold, isEligible } = useTokenFeeProgram();
  const fee = Number(buyTokenFeePercentage);
  let feeDiscount = '0';
  if (premiumBuyTokenFeePercentage) {
    feeDiscount = (((normalBuyTokenFeePercentage - premiumBuyTokenFeePercentage) / normalBuyTokenFeePercentage) * 100).toFixed(0);
  }
  let feeText = `A portion of each trade (${(fee * 100).toFixed(2)}%) is collected as fee.`
  const collectedFeeAmount = (fee * (isExactIn ? Number(quote.inputAmount.toSignificant(4)) : Number(quote.outputAmount.toSignificant(4)))).toFixed(3);
  const collectedToken = isExactIn ? quote.inputAmount.currency.symbol : quote.outputAmount.currency.symbol;
  let feeAmountText = `Amount collected ${collectedFeeAmount} ${collectedToken}`
  const defaultTokenAddress = getConfig().default_token_address;
  const waiveDefaultTokenAddress = getConfig().fee_waive_for_default_token;
  let waiveFeeForDefaultTokenAddress = false;
  let waiveSymbol;
  // If fee waive is enable, we put the fee collected as 0
  if (defaultTokenAddress && waiveDefaultTokenAddress) {
    if (quote.inputAmount.token.address?.toLowerCase() === defaultTokenAddress.toLowerCase() || quote.outputAmount.token.address?.toLowerCase() === defaultTokenAddress.toLowerCase()) {
      waiveFeeForDefaultTokenAddress = true;
    }
    if (quote.inputAmount.token.address?.toLowerCase() === defaultTokenAddress.toLowerCase()) {
      waiveSymbol = quote.inputAmount.token.symbol?.toUpperCase();
    }
    if (quote.outputAmount.token.address?.toLowerCase() === defaultTokenAddress.toLowerCase()) {
      waiveSymbol = quote.outputAmount.token.symbol?.toUpperCase();
    }
  }
  if(waiveFeeForDefaultTokenAddress){
    feeText = `Zero fee for trading ${waiveSymbol}`
    feeAmountText = `Amount collected 0.00 ${collectedToken}`
  }



  return (
    <>
      <AutoColumn style={{ padding: '0 20px' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              {isExactIn ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.text1} fontSize={14}>
              {isExactIn
                ? `${(Number(quote.inputAmount?.toSignificant(4)) * guarantedPrice).toFixed(4)} ${quote.outputAmount.currency.symbol}` ??
                '-'
                : `${(Number(quote.outputAmount?.toSignificant(4)) * guarantedPrice).toFixed(4)} ${quote.inputAmount.currency.symbol}` ??
                '-'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        {/* <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
       </RowBetween> */}

         <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Swap Fee
            </TYPE.black>
            <QuestionHelper text={feeText} />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {feeAmountText}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
      <FiatCard />
      {isProgramEnable && !waiveFeeForDefaultTokenAddress && <YellowCard style={{ marginTop: '12px', padding: '8px 4px' }}>
        <AutoColumn gap="sm" justify="center" style={{ alignItems: 'center', textAlign: 'center' }}>
          {isEligible && <Text lineHeight="145.23%;" fontSize={14} fontWeight={400} color={theme.text1}>
            Congrats! You are recieving a {feeDiscount}% discount on fees by holding minimum {tokenAmountToHold} {feeTokenAmount?.currency.symbol?.toUpperCase()}
          </Text>}
          {!isEligible && <Text lineHeight="145.23%;" fontSize={14} fontWeight={400} color={theme.text1}>
            Hold {tokenAmountToHold} {feeTokenAmount?.currency.symbol?.toUpperCase()} to recieve a {feeDiscount}% discount on fees.
                 Currently you hold {feeTokenAmount?.toFixed(2)} {feeTokenAmount?.currency.symbol?.toUpperCase()}.
                 <StyledInternalLink to={`/swap/${getConfig().token_fee_program?.token_address}`}>
              <b> Buy {feeTokenAmount?.currency.symbol?.toUpperCase()}</b>
            </StyledInternalLink>
          </Text>}
        </AutoColumn>
      </YellowCard>
      }
      {waiveFeeForDefaultTokenAddress &&
        <YellowCard style={{ marginTop: '12px', padding: '8px 4px' }}>
          <AutoColumn gap="sm" justify="center" style={{ alignItems: 'center', textAlign: 'center' }}>
            <Text lineHeight="145.23%;" fontSize={14} fontWeight={400} color={theme.text1}>
              {`Congrats, when you trade ${waiveSymbol} you have zero fees on the aggregator`}
            </Text>
          </AutoColumn>
        </YellowCard>}
    </>
  )
}

export interface AdvancedSwapAggDetailsProps {
  quote?: Quote
}

export function AdvancedSwapAggDetails({ quote }: AdvancedSwapAggDetailsProps) {
  // const theme = useContext(ThemeContext)

  const [allowedSlippage] = useUserSlippageTolerance()

  //  const showRoute = Boolean(quote && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {quote && (
        <>
          <QuoteSummary quote={quote} allowedSlippage={allowedSlippage} />
          {/*showRoute && (
            <>
              <SectionBreak />
              <AutoColumn style={{ padding: '0 24px' }}>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                    Route
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn> 
            </> 
          )*/}
        </>
      )}
    </AutoColumn>
  )
}
