import { Currency, Token, ChainId, isBSC } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/images/ethereum-logo.png'

import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { isNativeCoin } from '../../utils'


const getTokenLogoURL = (address: string, chainId: ChainId) => {
    if(isBSC(chainId)){
      switch (address) {
        case '0x314593fa9a2fa16432913dBcCC96104541d32D11':
          return `/images/assets/${address}.png`
        default:
          return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`
    
      }
    }


  switch (address) {
    case '0x7866E48C74CbFB8183cd1a929cd9b95a7a5CB4F4':
      return `/images/assets/${address}.png`
    case '0x9F9913853f749b3fE6D6D4e16a1Cc3C1656B6D51':
      return `/images/assets/${address}.png`
    default:
      return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

  }
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`


const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  chainId
}: {
  chainId: ChainId
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (isNativeCoin(chainId, currency)) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, `/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address, chainId)]
      }

      return [`/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address, chainId)]
    }
    return []
  }, [currency, uriLocations, chainId])

  if (isNativeCoin(chainId, currency)) {
   
    if(currency?.symbol?.toLowerCase() === 'bnb'){
      return <StyledEthereumLogo src={'/images/coins/wbnb.png'} size={size} style={style} />
    }
    if(currency?.symbol?.toLowerCase() === 'eth'){
      return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
    }

   
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
