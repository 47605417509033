import { SwapQuoteResponse, Quote } from "../utils/0x-api/types"
import { useEffect, useState } from "react"
import { CurrencyAmount, TradeType, Price, JSBI, Token, TokenAmount, Percent, ChainId, GET_ETHER } from "@uniswap/sdk"
import { getConfig } from "../constants/config";
import { GET_BASE_0X_API } from "../constants";




/**
 * Returns the best trade for the token in to the exact amount of token out
 */
/*export function use0xApiQuote(inputCurrency?: Token, outputCurrency?: Token, currencyAmountOut?: CurrencyAmount, allowedSplippage: number = 0.1): Promise<Quote | null> | null {
  return useMemo(() => {
    if (inputCurrency && outputCurrency && currencyAmountOut) {
      const inputAddress = inputCurrency.symbol?.toUpperCase() === 'ETH' ? 'ETH' : inputCurrency.address;
      const outputAddress = outputCurrency.symbol?.toUpperCase() === 'ETH' ? 'ETH' : outputCurrency.address;
     
      return fetch(
        `https://api.0x.org/swap/v0/quote?sellToken=${inputAddress}&buyToken=${outputAddress}&sellAmount=${currencyAmountOut.raw.toString()}&slippagePercentage=${allowedSplippage / 1000}`
      ).then(r => {
        return r.json() as unknown as SwapQuoteResponse;
      }).then(quoteResponse => {
  

        const inputAmount = new TokenAmount(inputCurrency, JSBI.BigInt(quoteResponse.buyAmount.toString()));
        const outputAmount = new TokenAmount(outputCurrency, currencyAmountOut.toExact());
        const executionPrice = new Price(inputAmount.currency, outputAmount.currency, inputAmount.raw, outputAmount.raw);
        const nextMidPrice = new Price(inputAmount.currency, outputAmount.currency, inputAmount.raw, outputAmount.raw);
        const priceImpact = new Percent('0');

        return {
          response: quoteResponse,
          tradeType: TradeType.EXACT_INPUT,
          inputAmount,
          outputAmount,
          executionPrice,
          nextMidPrice,
          priceImpact,
        }


      })
        .catch((e) => {
          console.log(e);
          return null;
        })
    }
    return null
  }, [inputCurrency, outputCurrency, currencyAmountOut, allowedSplippage])
}*/

export enum QuoteLoadingState{
  NOT_LOADED,
  FETCHING,
  FETCHED,
  FAILURE,
}



/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function use0xApiQuoteEffect(inputCurrency?: Token, outputCurrency?: Token, currencyAmount?: CurrencyAmount, allowedSlippage: number = 100, isIn?: boolean, buyTokenPercentageFee?: string, takerAddress?: string | null, feeRecipient?: string, chainId?: ChainId  ): {quote: Quote | undefined, quoteState: QuoteLoadingState} {
  const [quote, setQuote] = useState<Quote | undefined>();
  const [quoteState, setQuoteState] = useState<QuoteLoadingState>(QuoteLoadingState.NOT_LOADED);
  const [lastTimeQuote, setLastTimeQuote] = useState<number>(Date.now());
  const [outdatedQuote, setOutdatedQuote] = useState<boolean>(false);
  useEffect(() => {
    if (inputCurrency && outputCurrency && currencyAmount  &&  Date.now() - lastTimeQuote > 1000 && chainId ) {
      const inputAddress = inputCurrency.symbol?.toUpperCase() === GET_ETHER(chainId).symbol ? GET_ETHER(chainId).symbol : inputCurrency.address;
      const outputAddress = outputCurrency.symbol?.toUpperCase() === GET_ETHER(chainId).symbol ? GET_ETHER(chainId).symbol : outputCurrency.address;
      // Independent field is the input, if not is the output
      const amount = isIn ? `sellAmount=${currencyAmount.raw.toString()}` : `buyAmount=${currencyAmount.raw.toString()}`
      const config = getConfig();
      const takerAddressString = takerAddress ? `&takerAddress=${takerAddress}` : '';
      setQuoteState(QuoteLoadingState.FETCHING);
      
      fetch(
        // `https://api.0x.org/swap/v1/quote?sellToken=${inputAddress}&buyToken=${outputAddress}&${amount}&slippagePercentage=${allowedSplipage/10000}&feeRecipient=${config.feeRecipient}&buyTokenPercentageFee=${config.buyTokenPercentage}&affiliateAddress=${config.affiliateAddress}`
        `${GET_BASE_0X_API[chainId]}quote?sellToken=${inputAddress}&buyToken=${outputAddress}&${amount}&feeRecipient=${feeRecipient || config.feeRecipient}&buyTokenPercentageFee=${buyTokenPercentageFee}&affiliateAddress=${config.affiliateAddress}&skipValidation=true${takerAddressString}&slippagePercentage=${allowedSlippage / 10000} `
      ).then(r => {
        return r.json() as unknown as SwapQuoteResponse;
      }).then(quoteResponse => {
        
        setLastTimeQuote(Date.now())
        
        const inputAmount = new TokenAmount(inputCurrency, isIn ? currencyAmount.raw : JSBI.BigInt(quoteResponse.sellAmount.toString()));
        const outputAmount = new TokenAmount(outputCurrency, isIn ? JSBI.BigInt(quoteResponse.buyAmount.toString()) : currencyAmount.raw );
        const executionPrice = new Price(inputAmount.currency, outputAmount.currency, inputAmount.raw, outputAmount.raw);
        const nextMidPrice = new Price(inputAmount.currency, outputAmount.currency, inputAmount.raw, outputAmount.raw);
        const priceImpact = new Percent('0');
        setQuoteState(QuoteLoadingState.FETCHED);

        setQuote({
          response: quoteResponse,
          tradeType: isIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
          inputAmount,
          outputAmount,
          executionPrice,
          nextMidPrice,
          priceImpact,
          allowedSlippage,
          takerAddress,
          buyTokenPercentage: buyTokenPercentageFee,
          feeRecipient: config.feeRecipient,
          affiliateAddress: config.affiliateAddress,


        });

        setOutdatedQuote(false);
        setTimeout( () => {
          setOutdatedQuote(true);
        }, 10000)
       

      })
        .catch((e) => {
          setQuoteState(QuoteLoadingState.FAILURE);
          setTimeout( () => {
            setQuoteState(QuoteLoadingState.NOT_LOADED);
          }, 2000)
          return null;
        })
        // TODO: Get market prices to estimate Price Impact
    }


  }, [inputCurrency?.address, outputCurrency?.address, currencyAmount?.raw.toString(), allowedSlippage, isIn, outdatedQuote, buyTokenPercentageFee, takerAddress, lastTimeQuote, feeRecipient ]);

  return {quote, quoteState};

}
