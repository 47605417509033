import { useMemo } from 'react'

import { useTransactionAdder } from '../state/transactions/hooks'
import { isAddress, shortenAddress } from '../utils'

import useENS from './useENS'

import { Quote } from '../utils/0x-api/types'
import { useActiveWeb3React } from './index'
import { JSBI } from '@uniswap/sdk'
import { fetchQuote } from '../utils/0x-api/fetchQuote'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}


// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapAggCallback(
  quote: Quote | undefined, // trade to execute, required
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!quote || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const quoteFinal = await fetchQuote(quote, chainId);
        quoteFinal.response.from = recipient;
        const params =
          [
            {
              from: recipient,
              to: quoteFinal.response.to,
              value: JSBI.BigInt(quoteFinal.response.value).toString(16),
              data: quoteFinal.response.data,
              gasPrice: JSBI.BigInt(quoteFinal.response.gasPrice).toString(16),
              gas: JSBI.BigInt(Number(quoteFinal.response.gas)).toString(16)

            }
          ];

        return library.send('eth_sendTransaction', params)
          .then((hash: any) => {
            // patch this to be compatible
            const response = {hash: ' '};
            response.hash = hash;

            const inputSymbol = quoteFinal.inputAmount.currency.symbol
            const outputSymbol = quoteFinal.outputAmount.currency.symbol
            const inputAmount = quoteFinal.inputAmount.toSignificant(3)
            const outputAmount = quoteFinal.outputAmount.toSignificant(3)

            const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
            const withRecipient =
              recipient === account
                ? base
                : `${base} to ${
                recipientAddressOrName && isAddress(recipientAddressOrName)
                  ? shortenAddress(recipientAddressOrName)
                  : recipientAddressOrName
                }`

            addTransaction(response as any, {
              summary: withRecipient
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null
    }
  }, [quote, library, account, chainId, recipient, recipientAddressOrName, addTransaction])
}
