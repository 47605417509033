import { currencyEquals, ChainId } from '@uniswap/sdk'
import React, { useCallback, useMemo } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'

import {  Quote } from '../../utils/0x-api/types'
import SwapAggModalHeader from './SwapAggModalHeader'
import SwapAggModalFooter from './SwapAggModalFooter'



/**
 * Returns true if the quote requires a confirmation of details before we can submit it
 * @param quoteA quote A
 * @param quoteB quote B
 */
function quoteMeaningfullyDiffers(quoteA: Quote, quoteB: Quote): boolean {
  return (
    quoteA.tradeType !== quoteB.tradeType ||
    !currencyEquals(quoteA.inputAmount.currency, quoteB.inputAmount.currency) ||
    !quoteA.inputAmount.equalTo(quoteB.inputAmount) ||
    !currencyEquals(quoteA.outputAmount.currency, quoteB.outputAmount.currency) ||
    !quoteA.outputAmount.equalTo(quoteB.outputAmount)
  )
}

export default function ConfirmSwapAggModal({
  quote,
  originalQuote,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  chainId
}: {
  isOpen: boolean
  quote: Quote | undefined
  originalQuote: Quote | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: number
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
  chainId: ChainId
}) {
  const showAcceptChanges = useMemo(
    () => Boolean(quote && originalQuote && quoteMeaningfullyDiffers(quote, originalQuote)),
    [originalQuote, quote]
  )

  const modalHeader = useCallback(() => {
    return quote ? (
      <SwapAggModalHeader
        quote={quote}
        allowedSlippage={allowedSlippage}
        recipient={recipient}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
        chainId={chainId}
      />
    ) : null
  }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, quote])

  const modalBottom = useCallback(() => {
    return quote ? (
      <SwapAggModalFooter
        onConfirm={onConfirm}
        quote={quote}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={swapErrorMessage}
        allowedSlippage={allowedSlippage}
      />
    ) : null
  }, [allowedSlippage, onConfirm, showAcceptChanges, swapErrorMessage, quote])

  // text to show while loading
  const pendingText = `Swapping ${quote?.inputAmount?.toSignificant(6)} ${
    quote?.inputAmount?.currency?.symbol
  } for ${quote?.outputAmount?.toSignificant(6)} ${quote?.outputAmount?.currency?.symbol}`

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Swap"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
