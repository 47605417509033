import { ChainId, GET_ETHER, isBSC } from '@uniswap/sdk'
import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

//import Logo from '../../assets/svg/logo.svg'
// import LogoDark from '../../assets/svg/logo_white.svg'
//import Wordmark from '../../assets/svg/wordmark.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager, useUserChainIdManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Fiat from '../Fiat'
import Menu from '../Menu'
import ToggleChain from '../ToggleChain'
// import Row, { RowBetween } from '../Row'
import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import { getConfig } from '../../constants/config'
import { useHistory } from 'react-router-dom'
import { getRouteBasedOnChainId, getCurrentChainId } from '../../utils/chain'
import { queryParametersToSwapState } from '../../state/swap/hooks'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { Field, replaceSwapState } from '../../state/swap/actions'
import { getDefaultTokenAddress } from '../../utils/config'

// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

/*const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`*/

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

/*const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 4.5rem;
    }
  `};
`*/

const LogoIcon = styled.div`
  img { 
    width: 10rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 4.5rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSCMAINNET]: 'Binance Smart Chain',
  [ChainId.BSCTESTNET]: 'Binance Smart Chain Testnet'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>()
  const [userChainId, setChainId] = useUserChainIdManager()
  const currentChainId = getCurrentChainId(userChainId, chainId, account);
  const userEthBalance = useETHBalances(account ? [account] : [], currentChainId)?.[account ?? '']
  const [isDark] = useDarkModeManager()
  const config = getConfig();
  const supportBSC = config.support_bsc;

  const LogoPath = isDark ? config.logo_dark : config.logo;
  const parsedQs = useParsedQueryString()

  const handleChainSelect = useCallback(
    () => {
      const newChainId = isBSC(userChainId) ? ChainId.MAINNET : ChainId.BSCMAINNET;
      setChainId(newChainId)
      history.push(`${getRouteBasedOnChainId(newChainId)}/swap`)
      const parsed = queryParametersToSwapState(parsedQs, newChainId)
      // If not defined at begin we just setup the default token address
      const outputCurrencyId = parsed[Field.OUTPUT].currencyId === '' ? getDefaultTokenAddress(newChainId) : parsed[Field.OUTPUT].currencyId;

      dispatch(
        replaceSwapState({
          typedValue: parsed.typedValue,
          field: parsed.independentField,
          inputCurrencyId: parsed[Field.INPUT].currencyId,
          outputCurrencyId,
          recipient: parsed.recipient,
          affiliate: parsed.affiliate
        })
      )

    },
    [userChainId, history, parsedQs]
  )

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'flex-start' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            <LogoIcon>
              <img src={LogoPath} alt="logo" />
            </LogoIcon>
            {/*  <TitleText>
              <img style={{ marginLeft: '4px', marginTop: '4px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" />
            </TitleText>*/}
          </Title>
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            {supportBSC && <ToggleChain
              id="toggle-chain-button"
              isActive={isBSC(userChainId) ? false : true}
              toggle={
                handleChainSelect
              } />}
            <Fiat />
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} {GET_ETHER(currentChainId).symbol}
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/*<VersionSwitch />*/}

            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
