
import React from 'react'
import styled from 'styled-components'
import { getHidePoweredByDexKit } from '../../utils/config';
import { RowBetween } from '../Row';
import { useDarkModeManager } from '../../state/user/hooks';


const StyledDiv = styled.div` 
margin-top: 1rem;
`


export default function PoweredByDexKit() {
  const [isDark] = useDarkModeManager()
  const poweredByDexKitPath = isDark ? 'poweredByDexKitDark.png' : 'poweredByDexKitLight.png';

  const hidePoweredByDexKit = getHidePoweredByDexKit();
  return !hidePoweredByDexKit ?
    <StyledDiv>
      <RowBetween>
        <a href="https://dexkit.com" target="_blank">
          <img src={poweredByDexKitPath} alt="PoweredByDexKit" width="200" height="40" />
        </a>
      </RowBetween>
    </StyledDiv> : null
}
