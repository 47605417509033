import { getConfig } from "../constants/config"
import { SUPPORTED_WALLETS_WITHOUT_KEY, WalletInfo, SUPPORTED_WALLETS_WITH_KEY, SUPPORTED_BSC_WALLETS } from "../constants";
import { isBSC, ChainId } from "@uniswap/sdk";


export default function getSupportedWallets(chainId: ChainId): {[key: string]: WalletInfo} {
    if(isBSC(chainId)){
        const wallets: {[key: string]: WalletInfo} = SUPPORTED_BSC_WALLETS;
        return wallets;
    }



    const config = getConfig();
    const wallets: {[key: string]: WalletInfo} = SUPPORTED_WALLETS_WITHOUT_KEY;
    const wallet_config = config.wallets;
    if(wallet_config){
        if(wallet_config.portis){
            wallets['PORTIS'] = SUPPORTED_WALLETS_WITH_KEY['PORTIS'];
        }
        if(wallet_config.fortmatic){
            wallets['FORTMATIC'] = SUPPORTED_WALLETS_WITH_KEY['FORTMATIC'];
        }
        return wallets;
    }else {
        return wallets;
    }

}