import React, { useContext, } from 'react'
//import { CreditCard } from 'react-feather'

import { Text } from 'rebass'
import transakSDK from '@transak/transak-sdk'
import { useActiveWeb3React } from '../../hooks'
import { YellowCard } from '../Card'
import { AutoColumn } from '../Column'

import { useETHBalances } from '../../state/wallet/hooks'
import { ThemeContext } from 'styled-components'
import { LinkStyledButton } from '../../theme'
import { useUserChainId } from '../../state/user/hooks'
import { GET_ETHER } from '@uniswap/sdk'
import { getCurrentChainId } from '../../utils/chain'


export default function FiatCard() {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId();
  const currentChainId = getCurrentChainId(userChainId, chainId, account);
  const ethBalances = useETHBalances(account ? [account] : [], currentChainId)
  const ethBalance = account ? ethBalances[account] : undefined;

  const toggle = () => {
    let transak = new transakSDK({
      apiKey: '4cf44cc4-69d7-4f4d-8237-05cc9076aa41',  // Your API Key
      environment: 'PRODUCTION', // STAGING/PRODUCTION
      defaultCryptoCurrency: GET_ETHER(currentChainId).symbol?.toUpperCase(),
      walletAddress: account, // Your customer's wallet address
      themeColor: '000000', // App theme color
      fiatCurrency: '', // INR/GBP
      email: '', // Your customer's email address
      redirectURL: '',
      hostURL: window.location.origin,
      widgetHeight: '570px',
      widgetWidth: '450px',
      isFeeCalculationHidden: true,
      hideMenu: true,
      isDisableCrypto: false,
    });

    transak.init();

    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (data: any) => {
      transak.close();
      const w = (window as any)
      if(w){
          // TODO: remove this
          // workaround to remove annoying css bug
          setTimeout(()=>{
              w.document.documentElement.style = 0;   
          }, 10)
      }
  });


  }

  return (
    (account && ethBalance && Number(ethBalance?.toFixed(2)) < 0.15    ) ? <YellowCard style={{ marginTop: '12px', padding: '8px 4px' }}>
      <AutoColumn gap="sm" justify="center" style={{ alignItems: 'center', textAlign: 'center' }}>
        <Text lineHeight="145.23%;" fontSize={14} fontWeight={400} color={theme.text1}>
          We recommend have at least minimim 0.15 ${GET_ETHER(currentChainId).symbol?.toUpperCase()} on your wallet to do swaps.
               <LinkStyledButton onClick={toggle}>
            <b>Buy {GET_ETHER(currentChainId).symbol?.toUpperCase()} here.</b>
          </LinkStyledButton>
        </Text>
      </AutoColumn>
    </YellowCard> : null
  )
}
