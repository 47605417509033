import { Quote, SwapQuoteResponse } from "./types";
import { TradeType, JSBI, TokenAmount, ChainId, GET_ETHER } from "@uniswap/sdk";
import { GET_BASE_0X_API } from "../../constants";


/**
 * Fetch quote right before confirm, with final validation
 * @param quote 
 */
export async function fetchQuote(quote: Quote, chainId: ChainId): Promise<Quote>{
    const isIn = quote.tradeType === TradeType.EXACT_INPUT;
    const inputAddress = quote.inputAmount.currency.symbol?.toUpperCase() === GET_ETHER(chainId).symbol ? GET_ETHER(chainId).symbol : quote.inputAmount.token.address;
    const outputAddress = quote.outputAmount.currency.symbol?.toUpperCase() === GET_ETHER(chainId).symbol ? GET_ETHER(chainId).symbol : quote.outputAmount.token.address;
    const currencyAmount = isIn ? quote.inputAmount.raw : quote.outputAmount.raw;
    const amount = isIn ? `sellAmount=${currencyAmount.toString()}` : `buyAmount=${currencyAmount.toString()}`
    const allowedSlippage = quote.allowedSlippage;
    const takerAddressString = quote.takerAddress ? `&takerAddress=${quote.takerAddress}` : '';
    const buyTokenPercentageFee = quote.buyTokenPercentage;
    const affiliateAddress = quote.affiliateAddress;
    const feeRecipient = quote.feeRecipient;

    const quoteResponse = await fetch(
        `${GET_BASE_0X_API[chainId]}quote?sellToken=${inputAddress}&buyToken=${outputAddress}&${amount}&feeRecipient=${feeRecipient}&buyTokenPercentageFee=${buyTokenPercentageFee}&affiliateAddress=${affiliateAddress}${takerAddressString}&slippagePercentage=${allowedSlippage / 10000} `
      ).then(r => {
        if(r.status === 200){
          return r.json() as unknown as SwapQuoteResponse;
        }else{
          throw Error('Swap will fail. Do you have enough ETH for the transaction? Leave at least 0.03 ETH on your wallet for swaps. Contact support if error persists');
        }
    });
    quote.response = quoteResponse;
    quote.inputAmount = new TokenAmount(quote.inputAmount.token, isIn ? currencyAmount : JSBI.BigInt(quoteResponse.sellAmount.toString()));
    quote.outputAmount= new TokenAmount(quote.outputAmount.token, isIn ? JSBI.BigInt(quoteResponse.buyAmount.toString()) : currencyAmount )

    return quote;
}