import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../index'
import {
  updateMatchesDarkMode,/*, updateUserChainId*/
  updateUserChainId
} from './actions'
import { useActiveWeb3React } from '../../hooks'
import { isBSC, ChainId } from '@uniswap/sdk'
import { useHistory, useLocation } from 'react-router-dom'
import { getRouteBasedOnChainId } from '../../utils/chain'
import { setSelectedList, selectList } from '../lists/actions'
import PANCAKE_SWAP_LIST from '../../constants/token/pancakeswap.json'
import { useSelectedListUrl } from '../lists/hooks'
import { DEFAULT_TOKEN_LIST_URL } from '../../constants/lists'

/*import { useParams } from 'react-router-dom'
import { ChainId } from '@uniswap/sdk'*/

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>()

  // keep dark mode in sync with the system
  useEffect(() => {
    const darkHandler = (match: MediaQueryListEvent) => {
      dispatch(updateMatchesDarkMode({ matchesDarkMode: match.matches }))
    }

    const match = window?.matchMedia('(prefers-color-scheme: dark)')
    dispatch(updateMatchesDarkMode({ matchesDarkMode: match.matches }))

    if (match?.addListener) {
      match?.addListener(darkHandler)
    } else if (match?.addEventListener) {
      match?.addEventListener('change', darkHandler)
    }

    return () => {
      if (match?.removeListener) {
        match?.removeListener(darkHandler)
      } else if (match?.removeEventListener) {
        match?.removeEventListener('change', darkHandler)
      }
    }
  }, [dispatch])

 

  return null
}


export function ChainUpdater(): null {
  const { chainId, account } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory();
  const location = useLocation();
  const list = useSelectedListUrl();

  useEffect(() => {
    if (chainId && account) {
      dispatch(updateUserChainId({ userChainId: chainId }))

      if (isBSC(chainId)) {
        history.push(`${getRouteBasedOnChainId(chainId)}/swap`)
      }
      if(list === 'pancakeswap' && chainId === ChainId.BSCMAINNET){
        dispatch(selectList(DEFAULT_TOKEN_LIST_URL))
      }


    }
  }, [dispatch, chainId, account])


  useEffect(() => {
    const path = location.pathname.split('/')
    if (path.length > 2 && path[1].toLowerCase() === 'bsc' && !chainId) {
      dispatch(updateUserChainId({ userChainId: ChainId.BSCMAINNET }))
      dispatch(setSelectedList({url: 'pancakeswap', list: PANCAKE_SWAP_LIST })) 
    }else{
      // If list is pancake swap and we are at ethereum network, just put it as default Uniswap back
      if(list === 'pancakeswap'){
        dispatch(selectList(DEFAULT_TOKEN_LIST_URL))
      }
    }
    
   

  }, [dispatch, chainId, location, list])



  return null
}