import React, { useRef,  } from 'react'
//import { CreditCard } from 'react-feather'
import styled from 'styled-components'
import { Text } from 'rebass'
import transakSDK from '@transak/transak-sdk'
import { useActiveWeb3React } from '../../hooks'
import { useUserChainId } from '../../state/user/hooks'
import { GET_ETHER } from '@uniswap/sdk'
import { getCurrentChainId } from '../../utils/chain'

/*const StyledMenuIcon = styled(CreditCard)`
  width: 20px;
 
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`*/

const StyledImgIcon = styled.img`
  width: 20px;
  height: 18px;
  margin: 1px;
 
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`


const StyledMenuButton = styled.button`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 30px;
  background-color: ${({ theme }) => theme.bg3};

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
`


const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const BuyText = styled(Text)`
    color: ${({ theme }) => theme.text1};
    width: 80px;
    padding: 2px;
    font-weight: 700;
    font-size: 1rem;
`



export default function FiatTab() {
  const node = useRef<HTMLDivElement>()
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId()
  const currentChainId = getCurrentChainId(userChainId, chainId, account);

  const toggle = () => {
    let transak = new transakSDK({
      apiKey: '4cf44cc4-69d7-4f4d-8237-05cc9076aa41',  // Your API Key
      environment: 'PRODUCTION', // STAGING/PRODUCTION
      defaultCryptoCurrency: GET_ETHER(currentChainId).symbol?.toUpperCase(),
      walletAddress: account, // Your customer's wallet address
      themeColor:   '000000', // App theme color
      fiatCurrency: '', // INR/GBP
      email: '', // Your customer's email address
      redirectURL: '',
      hostURL: window.location.origin,
      widgetHeight: '570px',
      widgetWidth: '450px',
      isFeeCalculationHidden: true,
      hideMenu: true,
      isDisableCrypto: false,
  });
  
     transak.init();

     transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (data: any) => {
      transak.close();
      const w = (window as any)
      if(w){
          // TODO: remove this
          // workaround to remove annoying css bug
          setTimeout(()=>{
              w.document.documentElement.style = 0;   
          }, 10)
      }
  });
  }

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>  
        <StyledMenuButton onClick={toggle} id="open-fiat-modal">
        <BuyText>BUY {GET_ETHER(currentChainId).symbol?.toUpperCase()}
        </BuyText>
        <StyledImgIcon src="visa_master.png"  alt="CreditCard"/>
       
      </StyledMenuButton>
    </StyledMenu>
  )
}
