import { getConfig } from "../constants/config"
import { ChainId, isBSC } from "@uniswap/sdk";


export const getDefaultTokenAddress = (chainId: ChainId) => {
    if(isBSC(chainId)){
        const default_address_bsc = getConfig().default_token_address_bsc;
        if(default_address_bsc){
            return default_address_bsc;
        }
        return '';
    }

    const default_address = getConfig().default_token_address;
    if(default_address){
        return default_address;
    }else{
        return '';
    }
}


export const getDefaultTokenList = () => {
    return getConfig().default_token_list;
}

export const getHidePoweredByDexKit = () => {
    return getConfig().hide_powered_by_dexkit ? true : false;
}

export const getDefaultDarkMode = ():boolean | null => {
    return getConfig().is_dark_mode ? true : null;
}