import configFile from './config.json';


interface Config {
    name: string,
    logo: string,
    logo_dark: string,
    domain: string,
    feeRecipient: string,
    buyTokenPercentage: string,
    affiliateAddress: string,
    brand_color: string,
    brand_color_dark: string,
    support_bsc?: boolean,
    default_token_list?: string;
    default_token_address?: string;
    default_token_address_bsc?: string;
    fee_waive_for_default_token?: boolean;
    is_dark_mode?: boolean;
    hide_powered_by_dexkit?: boolean;
    token_fee_program?: {
        token_address: string;
        token_amount: string;
        buyTokenPercentage: string;
    }
    links?:{
        about?: string,
        code?: string,
        docs?: string,
        discord?: string,
        telegram?: string,
        analytics?: string
    },
    wallets?:{
        fortmatic?: string,
        portis?: string; 
    }
}

let config: Config | undefined;

export const getConfig = (): Config => {
    if(!config){
        const domain =  window.location.hostname;
        config = (configFile as Array<Config>).find(c => c.domain === domain);
        if(!config){
            throw Error('Config not found for this domain');
        }
    }


    return config;
}