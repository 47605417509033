
import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../Row';
import {  useUserChainId } from '../../state/user/hooks';
import { ChainId } from '@uniswap/sdk';
import { YellowCard } from '../Card';
import { useActiveWeb3React } from '../../hooks';

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSCMAINNET]: 'Binance Smart Chain',
  [ChainId.BSCTESTNET]: 'Binance Smart Chain Testnet'
}

const StyledDiv = styled.div` 
margin-top: 1rem;
margin-bottom: 2rem;
`
const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

export default function ChainWarning() {
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId()
  const showWarning = account && chainId && userChainId !== chainId;
 
  return showWarning ?
    <StyledDiv>
      <RowBetween>
      <NetworkCard> Your wallet is at { chainId && NETWORK_LABELS[chainId]}, switch to {userChainId && NETWORK_LABELS[userChainId]}</NetworkCard>
      </RowBetween>
    </StyledDiv> : null
}
