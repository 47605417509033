import useENS from '../../hooks/useENS'
import { Version } from '../../hooks/useToggledVersion'
import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, JSBI, Token, TokenAmount, Trade, Percent, Fraction, GET_ETHER } from '@uniswap/sdk'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useV1Trade } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useTradeExactIn, useTradeExactOut } from '../../hooks/Trades'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isAddress, isNativeCoin } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useCurrencyBalances, useCurrencyBalance } from '../wallet/hooks'
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { SwapState } from './reducer'
import useToggledVersion from '../../hooks/useToggledVersion'
import { useUserSlippageTolerance, useUserChainId } from '../user/hooks'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import { Quote, } from '../../utils/0x-api/types'
import { use0xApiQuoteEffect, QuoteLoadingState } from '../../hooks/use0xAPIQuote'
import { BIPS_BASE, DEXKIT } from '../../constants'
import { getDefaultTokenAddress } from '../../utils/config'
import { getConfig } from '../../constants/config'
import { isDexKitSwapDomain } from '../../utils/dexkitdomain'
import { ChainId } from '../../utils/types'
import { getCurrentChainId } from '../../utils/chain'
// import { useTranslation } from 'react-i18next'

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency, chainId: ChainId) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency, chainId: ChainId) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency instanceof Token ? currency.address : isNativeCoin(chainId, currency) ? GET_ETHER(chainId).symbol?.toUpperCase() as string : ''
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency, chainId?: ChainId): CurrencyAmount | undefined {
  if (!value || !currency || !chainId) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed), chainId)
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D' // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some(token => token.address === checksummedAddress) ||
    trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
  )
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmount: CurrencyAmount | undefined
  v2Trade: Trade | undefined
  inputError?: string
  v1Trade: Trade | undefined
} {
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId()
  const currentChainId = getCurrentChainId(userChainId, chainId, account);

  const toggledVersion = useToggledVersion()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId, currentChainId)
  const outputCurrency = useCurrency(outputCurrencyId, currentChainId)

  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  ], currentChainId)




  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined, currentChainId )

  const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1]
  }

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  // get link to trade on v1, if a better rate exists
  const v1Trade = useV1Trade(isExactIn, currencies[Field.INPUT], currencies[Field.OUTPUT], parsedAmount)

  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
      (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
      (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts = v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)

  const slippageAdjustedAmountsV1 =
    v1Trade && allowedSlippage && computeSlippageAdjustedAmounts(v1Trade, allowedSlippage)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    toggledVersion === Version.v1
      ? slippageAdjustedAmountsV1
        ? slippageAdjustedAmountsV1[Field.INPUT]
        : null
      : slippageAdjustedAmounts
        ? slippageAdjustedAmounts[Field.INPUT]
        : null
  ]

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.symbol + ' balance'
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    inputError,
    v1Trade
  }
}

export function useTokenFeeProgram(): {
  buyTokenFeePercentage: string
  normalBuyTokenFeePercentage: number;
  premiumBuyTokenFeePercentage: number;
  isProgramEnable: boolean
  isEligible: boolean
  feeTokenAmount: CurrencyAmount | undefined
  tokenAmountToHold: string | undefined
} {
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId()
  const currentChainId = chainId || userChainId;
  let isProgramEnable = false;
  let isEligible = false;
  const tokenFeeProgramCurrency = useCurrency(getConfig().token_fee_program?.token_address, currentChainId);
  const ownerTokenFeeBalance = useCurrencyBalance(account ?? undefined, tokenFeeProgramCurrency ?? undefined, currentChainId);
  let buyTokenFeePercentage = getConfig().buyTokenPercentage;
  const normalBuyTokenFeePercentage = Number(buyTokenFeePercentage);


  const config = getConfig();
  let tokenAmountToHold = config.token_fee_program?.token_amount;
  const premiumBuyTokenFeePercentage = config.token_fee_program?.buyTokenPercentage ? Number(config.token_fee_program?.buyTokenPercentage) : 0;
  if (config.token_fee_program && ownerTokenFeeBalance && tokenAmountToHold) {
    isProgramEnable = true;
    if (ownerTokenFeeBalance.greaterThan(tokenAmountToHold)) {
      buyTokenFeePercentage = config.token_fee_program.buyTokenPercentage;
      isEligible = true;
    }
  }
  return {
    buyTokenFeePercentage,
    isEligible,
    isProgramEnable,
    feeTokenAmount: ownerTokenFeeBalance,
    normalBuyTokenFeePercentage,
    premiumBuyTokenFeePercentage,
    tokenAmountToHold
  }
}



// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapAggInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmount: CurrencyAmount | undefined
  quote: Quote | undefined
  priceImpact: Percent
  priceSlippagePercentage: Fraction | undefined
  inputError?: string
} {
  const { account, chainId } = useActiveWeb3React()
  const userChainId = useUserChainId()
  const currentChainId = getCurrentChainId(userChainId, chainId, account)
  // const { t } = useTranslation()
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient, 
    affiliate
  } = useSwapState()


  const inputCurrency = useCurrency(inputCurrencyId, currentChainId) as Token | undefined;
  const outputCurrency = useCurrency(outputCurrencyId, currentChainId) as Token | undefined;


  const recipientLookup = useENS(recipient ?? undefined)
  const affiliateLookup = useENS(affiliate ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null


  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  ], currentChainId)
  const kitTokenBalance = useCurrencyBalance(affiliateLookup.address ?? undefined, DEXKIT, currentChainId);

  let { buyTokenFeePercentage } = useTokenFeeProgram();
  const defaultTokenAddress = getConfig().default_token_address;
  const waiveDefaultTokenAddress = getConfig().fee_waive_for_default_token;
  // If fee waive is enable, we put the fee collected as 0
  if (defaultTokenAddress && waiveDefaultTokenAddress) {
    if (inputCurrencyId?.toLowerCase() === defaultTokenAddress.toLowerCase() || outputCurrencyId?.toLowerCase() === defaultTokenAddress.toLowerCase()) {
      buyTokenFeePercentage = '0';
    }

  }

  let feeRecipient = getConfig().feeRecipient;
  if(affiliateLookup.address && isDexKitSwapDomain()){
      if(kitTokenBalance?.greaterThan('5000')){
        feeRecipient = affiliateLookup.address;
      }
  }


  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined, currentChainId)
  const parsedAmountUnit = tryParseAmount('0.01', (isExactIn ? inputCurrency : outputCurrency) ?? undefined, currentChainId)
  let [allowedSlippage] = useUserSlippageTolerance()

  const { quote, quoteState } = use0xApiQuoteEffect(inputCurrency, outputCurrency, parsedAmount, allowedSlippage, isExactIn, buyTokenFeePercentage, to, feeRecipient, currentChainId);

  const quoteUnit = use0xApiQuoteEffect(inputCurrency, outputCurrency, parsedAmountUnit, allowedSlippage, isExactIn, buyTokenFeePercentage, undefined, undefined, currentChainId);
  let priceImpact = new Percent('0');
  let priceSlippagePercentage;
  if (quoteUnit.quote && quote) {
    const slippage = quoteUnit.quote.executionPrice.subtract(quote.executionPrice).divide(quoteUnit.quote.executionPrice);
    // If price slippage calculated less than zero set it to zero
    priceSlippagePercentage = slippage.lessThan('0') ? new Fraction('0') : slippage.multiply('100');
    quote.priceImpact = new Percent(slippage.multiply('10000').toFixed(0), BIPS_BASE);
  }

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1]
  }

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  let inputError: string | undefined
  if (!account) {
    // inputError =  t('connectWallet');
    inputError = 'Connect Wallet';
  }

  if (!parsedAmount) {
    // inputError = inputError ?? t('enterAmount');
    inputError = inputError ?? 'Enter Amount';
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    // inputError = inputError ?? t('selectToken')
    inputError = inputError ?? 'Select a Token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    //  inputError = inputError ??  t('enterRecipient')//'Enter a recipient'
    inputError = inputError ?? 'Enter a recipient'
  }

  if (quoteState === QuoteLoadingState.FETCHING) {
    //inputError = inputError ??  t('fetchingQuote')//'Fetching Quote'
    inputError = inputError ?? 'Fetching Quote'
  }

  if (quoteState === QuoteLoadingState.FAILURE) {
    // inputError = inputError ?? t('fetchingQuoteFailed')// 'Fetching Quote Failed'
    inputError = inputError ?? 'Fetching Quote Failed'
  }


  if (quoteState === QuoteLoadingState.NOT_LOADED) {
    // inputError = inputError ?? t('noQuote')// 'No Quote'
    inputError = inputError ?? 'No Quote'
  }
  const balanceIn = currencyBalances[isExactIn ? Field.INPUT : Field.OUTPUT];
  const amountIn = parsedAmount;

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.symbol + ' balance'
  }


  //const slippageAdjustedAmounts = v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)

  // compare input balance to max input based on version
  /*const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts
      ? slippageAdjustedAmounts[Field.INPUT]
      : null
  ]

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.symbol + ' balance'
  }*/

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    quote,
    priceImpact,
    priceSlippagePercentage,
    inputError,
  }
}

function parseCurrencyFromURLParameter(urlParam: any, chainId: ChainId): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    if (urlParam.toUpperCase() === GET_ETHER(chainId).symbol) return GET_ETHER(chainId).symbol || ''
    if (valid === false) return GET_ETHER(chainId).symbol || ''
  }
  return GET_ETHER(chainId).symbol ?? ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

function validatedAffiliate(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(parsedQs: ParsedQs, chainId: ChainId): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency, chainId)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency, chainId)
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)
  const affiliate = validatedAffiliate(parsedQs.affiliate)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    affiliate
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
    const { account, chainId } = useActiveWeb3React()
    const userChainId = useUserChainId()
    const currentChainId = getCurrentChainId(userChainId, chainId, account);
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!currentChainId) return

    const parsed = queryParametersToSwapState(parsedQs, currentChainId)
    // If not defined at begin we just setup the default token address
    const outputCurrencyId = parsed[Field.OUTPUT].currencyId === '' ? getDefaultTokenAddress(currentChainId) : parsed[Field.OUTPUT].currencyId;

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId,
        recipient: parsed.recipient,
        affiliate: parsed.affiliate
      })
    )

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}
