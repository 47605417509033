import { ChainId, isBSC } from "@uniswap/sdk";

export const getRouteBasedOnChainId = (chainId: ChainId) => {

    return isBSC(chainId) ? '/bsc' : ''; 


}

export const getCurrentChainId = (userChainId: ChainId, chainId?: ChainId, account?: string | null) => {
    if(account){
        return chainId || userChainId;
    }else{
        return userChainId;
    }
}